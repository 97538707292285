/* eslint-disable no-undef */
/* eslint-disable no-sequences */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-param-reassign */
// @flow
import Transport from 'winston-transport';

import type { TLogEntry, TCallback } from '../types';

declare var ym: any;

type TOpts = $ReadOnly<{|
	user: ?$ReadOnly<{|
		id: string,
		username: string,
		email: string,
	|}>,
	token: string,
	level: string,
|}>;

let yandexMetrikaIsInit = false;

class YandexTransport extends Transport {
	constructor(opts: TOpts) {
		const { token, user, ...otherOpts } = opts;
		super(otherOpts);

		if (!yandexMetrikaIsInit) {
			yandexMetrikaIsInit = true;
			this.token = token;

			(function (m, e, t, r, i, k, a) {
				m[i] =
					m[i] ||
					function () {
						(m[i].a = m[i].a || []).push(arguments);
					};
				m[i].l = 1 * new Date();
				(k = e.createElement(t)),
					(a = e.getElementsByTagName(t)[0]),
					(k.async = true),
					(k.src = r),
					a.parentNode?.insertBefore(k, a);
			})(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

			if (typeof ym !== 'undefined')
				ym(token, 'init', {
					clickmap: true,
					trackLinks: true,
					accurateTrackBounce: true,
					webvisor: true,
				});
		}
	}

	log(logEntry: TLogEntry, callback: TCallback) {
		const { message, meta = {}, level } = logEntry;

		if (level !== 'info') return;
		if (typeof ym !== 'undefined') ym(this.token, 'reachGoal', message, meta);

		callback(null, true);
	}
}

export default YandexTransport;
