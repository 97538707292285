// @flow
import Transport from 'winston-transport';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import type { TLogEntry, TCallback } from '../types';

type TOpts = $ReadOnly<{|
	user: ?$ReadOnly<{|
		id: string,
		username: string,
		email: string,
	|}>,
	token: string,
	level: string,
|}>;

let isLogrocketInit = false;

class LogRocketTransport extends Transport {
	constructor(opts: TOpts) {
		const { token, user, ...otherOpts } = opts;
		super(otherOpts);

		if (!isLogrocketInit) {
			isLogrocketInit = true;

			LogRocket.init(token, {
				console: {
					isEnabled: false,
				},
			});
			setupLogRocketReact(LogRocket);
		}
		if (user) {
			LogRocket.identify(user.id, {
				email: user.email,
				username: user.username,
			});
		}
	}

	// eslint-disable-next-line class-methods-use-this
	log(logEntry: TLogEntry, callback: TCallback) {
		const { message, meta = {}, level } = logEntry;

		if (level === 'info') {
			LogRocket.track(message, meta);
		} else if (level === 'error') {
			LogRocket.error(message, meta);
		} else {
			LogRocket.log(message, meta);
		}
		callback(null, true);
	}
}

export default LogRocketTransport;
