// eslint-disable-next-line import/no-extraneous-dependencies
import React from "react";
import { CacheProvider } from "@emotion/core";
import { cache, hydrate } from "emotion";

export const onClientEntry = () => {
	// eslint-disable-next-line no-undef
	const idsElem = document.getElementById(`emotion-extracted-ids`);

	if (idsElem) {
		const ids = JSON.parse(idsElem.innerHTML);
		hydrate(ids);
	}
};

export const wrapRootElement = ({ element }) => (
	// eslint-disable-next-line react/jsx-filename-extension
	<CacheProvider value={cache}>{element}</CacheProvider>
);
