// @flow
import Transport from 'winston-transport';
import mixpanel from 'mixpanel-browser';

import type { TLogEntry, TCallback } from '../types';

type TOpts = $ReadOnly<{|
	user: ?$ReadOnly<{|
		id: string,
		username: string,
		email: string,
	|}>,
	token: string,
	level: string,
|}>;

class MixPanelTransport extends Transport {
	constructor(opts: TOpts) {
		const { token, user, ...otherOpts } = opts;
		super(otherOpts);

		mixpanel.init(token);
		if (user) {
			mixpanel.identify(user.id);
			mixpanel.people.set({ email: user.email, username: user.username });
		}
	}

	// eslint-disable-next-line class-methods-use-this
	log(logEntry: TLogEntry, callback: TCallback) {
		const { message, meta = {}, level, props } = logEntry;

		if (props) mixpanel.people.set(props);
		if (level !== 'info') return;
		mixpanel.track(message, meta);
		callback(null, true);
	}
}

export default MixPanelTransport;
