// @flow

import winston from 'winston';
import _ from 'lodash/fp';

import { LEVELS } from './constants';

type TConfig = $ReadOnly<{|
	browser?: boolean,
	sentry?: $ReadOnly<{|
		DNS: string,
	|}>,
	analytics?: $ReadOnly<{|
		logEvent: (string, Object) => void,
	|}>,
	fb?: boolean,
	amplitude?: $ReadOnly<{|
		key: string,
	|}>,
	mixpanel?: $ReadOnly<{|
		key: string,
	|}>,
	yandex?: $ReadOnly<{|
		key: string,
	|}>,
	logRocket?: $ReadOnly<{|
		key: string,
	|}>,
|}>;

type TInfo = $ReadOnly<{|
	id: string,
	username: string,
	email: string,
|}>;

const Logger = (function Logger() {
	let config: ?TConfig = null;
	let info: ?TInfo = null;
	let logger;

	return {
		init: (configNew: TConfig, infoNew: ?TInfo) => {
			config = configNew;
			info = infoNew;
			logger = winston.createLogger({
				format: winston.format.json(),
				defaultMeta: { service: 'front' },
				transports: [],
			});

			const { browser } = config;
			if (browser) {
				// eslint-disable-next-line global-require
				const BrowserTransport = require('./libs/browser-transport').default;
				logger.add(
					new BrowserTransport({
						format: winston.format.json(),
						level: 'debug',
					}),
				);
			}

			const { sentry } = config;
			if (sentry && sentry.DNS) {
				// eslint-disable-next-line global-require
				const SentryTransport = require('./libs/sentry-transport').default;

				logger.add(
					new SentryTransport({
						format: winston.format.json(),
						level: 'error',
						sentryDNS: sentry.DNS,
						user: info && {
							id: info.id,
							username: info.username,
							email: info.email,
						},
						host: window.location.hostname,
					}),
				);
			}

			const { analytics } = config;
			if (analytics) {
				// eslint-disable-next-line global-require
				const AnalyticsTransport = require('./libs/analytics-transport').default;

				logger.add(
					new AnalyticsTransport({
						level: 'info',
						logEvent: analytics.logEvent,
					}),
				);
			}

			const { fb } = config;
			if (fb) {
				// eslint-disable-next-line global-require
				const FbTransport = require('./libs/fb-transport').default;
				logger.add(
					new FbTransport({
						level: 'info',
					}),
				);
			}

			const { amplitude } = config;
			if (amplitude && amplitude.key) {
				// eslint-disable-next-line global-require
				const AmplitudeTransport = require('./libs/amplitude-transport').default;
				logger.add(
					new AmplitudeTransport({
						amplitudeKey: amplitude.key,
						level: 'info',
						user: info && {
							id: info.id,
							username: info.username,
							email: info.email,
						},
					}),
				);
			}

			const { mixpanel } = config;
			if (mixpanel && mixpanel.key) {
				// eslint-disable-next-line global-require
				const MixPanelTransport = require('./libs/mixpanel-transport').default;
				logger.add(
					new MixPanelTransport({
						token: mixpanel.key,
						level: 'info',
						user: info && {
							id: info.id,
							username: info.username,
							email: info.email,
						},
					}),
				);
			}

			const { yandex } = config;
			if (yandex && yandex.key) {
				// eslint-disable-next-line global-require
				const YandexTransport = require('./libs/yandex-transport').default;
				logger.add(
					new YandexTransport({
						token: yandex.key,
						level: 'info',
						user: info && {
							id: info.id,
							username: info.username,
							email: info.email,
						},
					}),
				);
			}

			const { logRocket } = config;
			if (logRocket && logRocket.key) {
				// eslint-disable-next-line global-require
				const LogRocketTransport = require('./libs/logrocket-transport').default;
				logger.add(
					new LogRocketTransport({
						token: logRocket.key,
						level: 'debug',
						user: info && {
							id: info.id,
							username: info.username,
							email: info.email,
						},
					}),
				);
			}
		},
		..._.transform(
			// eslint-disable-next-line no-return-assign
			(result, level) =>
				// eslint-disable-next-line no-param-reassign
				(result[level] = (message, meta, props) => {
					if (logger) logger.log({ level, message, meta, props });
					// eslint-disable-next-line no-console
					else console.error('logger is not initial');
				}),
			{},
		)(LEVELS),
	};
})();

export default Logger;
