/* eslint-disable consistent-return */
// @flow
import _ from 'lodash/fp';
import Transport from 'winston-transport';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import type { TLogEntry, TCallback } from '../types';

type TOpts = $ReadOnly<{|
	format?: Object,
	level: string,
	user: ?$ReadOnly<{|
		id: string,
		username: string,
		email: string,
	|}>,
	host: ?string,
	sentryDNS: string,
|}>;

class SentryTransport extends Transport {
	constructor(opts: TOpts) {
		const { sentryDNS: dsn, user, host, ...otherOpts } = opts;
		super(otherOpts);

		this.sentryClient = Sentry.init({
			dsn,
			integrations: [new Integrations.BrowserTracing()],
			tracesSampleRate: 1.0,
			normalizeDepth: 10,
		});

		Sentry.configureScope(scope => {
			if (user) {
				scope.setUser(user);
			}
			if (host) {
				scope.setTag('host', host);
			}
		});
	}

	// eslint-disable-next-line class-methods-use-this
	log(logEntry: TLogEntry, callback: TCallback) {
		const { message, level } = logEntry;

		if (['error', 'crit', 'alert', 'emerg'].includes(level)) {
			let err = null;
			if (_.isError(logEntry)) {
				err = logEntry;
			} else {
				err = new Error(message);
				if (logEntry.stack) {
					err.stack = logEntry.stack;
				}
			}
			Sentry.captureException(err);
			return callback(null, true);
		}
		if (level === 'warning') {
			Sentry.captureMessage(message, level);
			return callback(null, true);
		}
	}
}

export default SentryTransport;
