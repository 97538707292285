module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/graphite/graphite/services/home/locales","languages":["en"],"fallbackLng":["en"],"defaultLanguage":"en","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false,"react":{"bindI18n":"languageChanged","bindI18nStore":"","transEmptyNodeValue":"","transSupportBasicHtmlNodes":true,"transKeepBasicHtmlNodesFor":["br","strong","i"],"useSuspense":true}}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"graphite-space","short_name":"graphite","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"aebed8185024c21d79a2b763169b56e9"},
    },{
      plugin: require('../plugins/emotion-true/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
